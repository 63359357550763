// eslint-disable-next-line import/prefer-default-export
export const listStudents = /* GraphQL */ `
    query ListStudents(
        $id: ID
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
#        $includeSchool: Boolean = false
        $includeApplications: Boolean = false
    ) {
        listStudents(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    first
                    last
                }
                grade
#                school  { #@include(if: $includeSchool)
#                    name {
#                        legal
#                    }
#                }
                applications @include(if: $includeApplications) {
                    items {
                        id
                        teacherID
                    }
                }
                schoolID
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listApplicationsByTeacher = /* GraphQL */ `
    query ListApplicationsByTeacher(
        $teacherID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
#        $includeSchool: Boolean = false
        $includeApplications: Boolean = false
    ) {
        listApplicationsByTeacher(
            teacherID: $teacherID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                student {
                    id
                    name {
                        first
                        last
                    }
                    grade
                    school {
                        name {
                            legal
                        }
                    }
                    applications @include(if: $includeApplications) {
                        items {
                            id
                            teacherID
                        }
                    }
                    schoolID
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;

export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                }
            }
            nextToken
        }
    }
`;


export const getStudentForDeletion = /* GraphQL */ `
    query GetStudent($id: ID!) {
        getStudent(id: $id) {
            id
            applications {
                items {
                    id
                    applicationSelectionId
                }
                nextToken
            }
            invoice {
                id
                payments {
                    items {
                        id
                    }
                }
            }
            studentInvoiceId
        }
    }
`;

export const deleteStudent = /* GraphQL */ `
    mutation DeleteStudent(
        $input: DeleteStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        deleteStudent(input: $input, condition: $condition) {
            id
        }
    }
`;

export const deleteInvoice = /* GraphQL */ `
    mutation DeleteInvoice(
        $input: DeleteInvoiceInput!
        $condition: ModelInvoiceConditionInput
    ) {
        deleteInvoice(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deletePayment = /* GraphQL */ `
    mutation DeletePayment(
        $input: DeletePaymentInput!
        $condition: ModelPaymentConditionInput
    ) {
        deletePayment(input: $input, condition: $condition) {
            id
        }
    }
`;

export const deleteApplication = /* GraphQL */ `
    mutation DeleteApplication(
        $input: DeleteApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        deleteApplication(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteSelection = /* GraphQL */ `
    mutation DeleteSelection(
        $input: DeleteSelectionInput!
        $condition: ModelSelectionConditionInput
    ) {
        deleteSelection(input: $input, condition: $condition) {
            id
        }
    }
`;

export const onCreateStudent = /* GraphQL */ `
    subscription OnCreateStudent {
        onCreateStudent {
            id
            name {
                first
                last
            }
            grade
            school {
                name {
                    legal
                }
            }
            applications {
                items {
                    id
                    teacherID
                }
            }
        }
    }
`;
export const onUpdateStudent = /* GraphQL */ `
    subscription OnUpdateStudent {
        onUpdateStudent {
            id
            name {
                first
                last
            }
            grade
            school {
                name {
                    legal
                }
            }
            applications {
                items {
                    id
                    teacherID
                }
            }
        }
    }
`;
export const onDeleteStudent = /* GraphQL */ `
    subscription OnDeleteStudent {
        onDeleteStudent {
            id
            name {
                first
                last
            }
            grade
            school {
                name {
                    legal
                }
            }
            applications {
                items {
                    id
                    teacherID
                }
            }
        }
    }
`;
