<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`All-State - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Students" active/>
    </template>

    <template #dropdown-options="{ }">
      <b-dropdown-item @click="refresh">
        <font-awesome-icon icon="fa-solid fa-rotate-right"></font-awesome-icon>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #tour="{ tourName }">
      <tour v-if="$refs['table-layout']" :name="tourName" :steps="$refs['table-layout'].tour.steps" :callbacks="$refs['table-layout'].tour.callbacks"/>
    </template>

    <template #content="{ state }">
        <table-layout ref="table-layout"
                      :fields="table.fields"
                      :filters="table.filters" :filters-options="{ visible: true, collapsed: true }"
                      :items="table.items"
                      :loading="table.loading"
                      :subscriptions="table.subscriptions"
                      :func-delete="deleteStudent"
                      :func-refresh="refresh"
                      :export-exclude-fields="[
                        'id',
                        'schoolID',
                        'school.id',
                      ]"
                      @mounted="table = $event"
                      @updated="table = $event">

          <template #overlay>
            <overlay-loading :items="[
            { state: options.schools.loading, desc: 'Loading Schools', loaded: options.schools.loaded },
            { state: table.loading, desc: 'Loading Students', loaded: table.loaded },
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
          </template>

          <!-- Filters -->
          <template #filters>
            <b-row>
              <b-col lg="6" sm="12">
                <b-form-group label="School" label-for="school-input">
                  <v-select v-model="table.filters.school.value"
                            input-id="school-input"
                            :options="schools" label="name"
                            :reduce="school => school.id"
                            :loading="options.schools.loading"
                            :filter="searchSchools"
                            :select-on-tab="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            class="w-100">
                    <template #option="{ name }">
                     {{ name.legal }}
                    </template>
                    <template #selected-option="{ name }">
                      {{ name.legal }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group label="Grade" label-for="grade-input">
                  <v-select v-model="table.filters.grade.value"
                            input-id="grade-input"
                            :options="['10', '11']"
                            :searchable="false"
                            :select-on-tab="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            class="w-100"/>
                </b-form-group>
              </b-col>
            </b-row>
          </template>

          <!-- Column: Name -->
          <template #cell(name)="{data}">
            <b-media vertical-align="center" no-body>
              <b-media-aside>
                <b-avatar v-if="hasCustomAvatar(data.item.avatar)" variant="primary" size="2.5em" badge-variant="white">
                  <b-img fluid fluid-grow :src="data.item.avatar.src" :alt="data.item.avatar.name"></b-img>
                </b-avatar>
                <b-avatar v-else variant="primary" size="2.5em" badge-variant="white">
                  <font-awesome-icon :icon="icon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="align-self-center">
                <b-link :to="{ name: 'all-state-student', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
                  {{ data.item.name.first }} {{ data.item.name.last }}
                </b-link>
              </b-media-body>
            </b-media>
          </template>

          <!-- Column: School -->
          <template #cell(school.name.legal)="{data}">
            <div v-if="data.item.school">
              {{ data.item.school.name.legal }}
            </div>
            <div v-else class="text-danger">
              No School
            </div>
          </template>

          <template #cell(createdAt)="{data}">
            <last-modified :date="data.item.createdAt" no-text />
          </template>

          <!-- Column: State - Enabled -->
          <template #cell(updatedAt)="{data}">
            <last-modified :date="data.item.updatedAt" no-text />
          </template>

          <!-- Column: State - Enabled -->
          <template #cell(state.enabled)="{data}">
            <b-badge pill :variant="`light-${data.item.state.enabled ? 'success' : 'danger'}`" class="text-capitalize">
              {{ data.item.state.enabled }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(row-options)="{data}">
            <b-dropdown-item :to="{ name: 'all-state-student', params: { id: data.item.id } }"
                             class="table-row-option-view">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
            <can do="delete" on="all-state-students">
              <b-dropdown-divider/>
              <b-dropdown-item class="table-row-option-delete"
                               @click="$refs.layout.confirmDelete(data.item, deleteStudent, cascadeConfirmDeleteOptions)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </can>
          </template>
        </table-layout>
    </template>

    <template #debug>
      <debug :collapsed="true">{{ table }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import TableActions from '@/components/TableActions.vue';
import SchoolModal from '@/views/management/schools/SchoolModal.vue'
import Tour from '@/components/Tour.vue';
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import Fuse from 'fuse.js';
import vSelect from 'vue-select';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {
  listApplicationsByTeacher,
  listStudents,
  listSchools,
  onCreateStudent,
  onDeleteStudent,
  onUpdateStudent
} from '@/graphql/queries/students';
import { cascadeDeleteStudent, cascadeConfirmDeleteOptions} from '@/graphql/cascade/student';
import settingsMixin from '@/mixins/settings.mixin';
import LastModified from '@/components/LastModified.vue';

export default {
  name: 'Students',
  components: {
    LastModified,
    PageLayout,
    TableLayout,
    TableActions,
    OverlayLoading,
    SchoolModal,
    vSelect,
    Tour
  },
  mixins: [ avatar, notify, print, settingsMixin ],
  data() {
    return {
      user: null,
      table: {
        fields: [
          {
            key: 'name',
            subKeys: ['name.first', 'name.last', 'name.full'],
            label: 'Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'school.name.legal',
            label: 'School',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle',
            isQueryField: false
          },
          {
            key: 'grade',
            label: 'Grade',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'applications.items.length',
            label: 'Applications',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle',
            isQueryField: true
          },
          {
            key: 'createdAt',
            label: 'Created',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'updatedAt',
            label: 'Last Modified',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        filters: {
          school: { key: 'schoolID', value: null },
          grade: { key: 'grade', value: null },
        },
        items: [],
        loading: true,
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        }
      },
      options: {
        schools: {
          items: [],
          loading: false,
          loaded: false
        }
      },
      icon: 'fas fa-graduation-cap',
      debug: { },
      cascadeConfirmDeleteOptions,
    }
  },
  computed: {
    schools() {
      if(this.user?.groups?.includes('Teacher') && this.table.items.length) {
        return [...new Map(this.table.items.filter(item => item.school).map(v => [v.schoolID, v.school])).values()] || []
      }
      return this.options.schools.items
    }
  },
  async created() {
    await this.getUser();
    if(this.user.groups.includes('Teacher')) {
      await this.listStudents();
    }
    else {
      await this.listSchools();
      await this.listStudents();
    }

    this.onCreateStudent();
    this.onUpdateStudent();
    this.onDeleteStudent();
  },
  mounted() {
    this.$refs.layout.state.loading = false
  },
  methods: {
    /** User **/
    async getUser() {
      const user = await Auth.currentAuthenticatedUser()
      this.user = {
        id: user.attributes['custom:user_id'],
        groups: user.signInUserSession.accessToken.payload['cognito:groups']
      }
    },

    /** Table Data **/
    async listSchools(nextToken, pagedSchools) {
      this.options.schools.loading = true
      const schools = pagedSchools || []
      const response = await API.graphql(graphqlOperation(listSchools, { limit: 500, nextToken: nextToken }));
      schools.push(...response.data.listSchools.items);
      this.options.schools.loaded = schools.length

      if(response.data.listSchools.nextToken) {
        await this.listSchools(response.data.listSchools.nextToken, schools)
      }
      else {
        this.options.schools.items = schools
        this.options.schools.loading = false
        this.options.schools.loaded = true
      }
    },

    async listStudents() {
      //console.time('listStudents')
      let nextToken = null;
      const students = [];
      const inputCommon = {
        limit: 1000,
        filter: {
          createdAt: { between: [this.settingsStore.app.current.year.start, this.settingsStore.app.current.year.end] }
        },
        includeApplications: this.table.fields.find(field => field.key === 'applications.items.length')?.visible || false,
      };

      const isTeacher = this.user.groups.includes('Teacher');
      const includeSchool = this.table.fields.find(field => field.key === 'school.name.legal')?.visible || false;
      const schoolsMap = new Map(this.options.schools.items.map(school => [school.id, school]));


      /* eslint-disable no-await-in-loop */
      do {
        let response;
        if (isTeacher) {
          const inputTeacher = {
            ...inputCommon,
            teacherID: this.user.id,
            includeSchool: includeSchool,
            nextToken: nextToken,
          };
          response = await API.graphql(graphqlOperation(listApplicationsByTeacher, inputTeacher));
          students.push(...response.data.listApplicationsByTeacher.items.map(item => item.student));
          nextToken = response.data.listApplicationsByTeacher.nextToken;
        } 
        else {
          const inputOthers = {
            ...inputCommon,
            nextToken: nextToken
          };
          response = await API.graphql(graphqlOperation(listStudents, inputOthers));
          students.push(...response.data.listStudents.items);
          nextToken = response.data.listStudents.nextToken
        }
      } 
      while (nextToken);
      /* eslint-enable no-await-in-loop */

      if (isTeacher) {
        this.table.items = [...new Map(students.map(v => [v.id, v])).values()];
      } 
      else {
        this.table.items = students.map(student => ({
          ...student,
          school: schoolsMap.get(student.schoolID)
        }));
      }

      this.table.items.forEach(student => {
        student.name.full = `${student.name?.first} ${student.name?.last}`;
      });
      this.table.loaded = students.length;
      this.table.loading = false;
      //console.timeEnd('listStudents')
    },
    
    
    
    async deleteStudent(student, swalCallback) {
      try {
        this.table.loading = true
        await this.cascadeDeleteStudent(student.id, swalCallback)
        this.table.items = this.table.items.filter(item => item.id !== student.id);
        this.notify({ title: 'Success', text: 'Student was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch (error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Student failed to delete', icon: this.icon, variant: 'danger' });
        throw error //for Swal
      }
      finally {
        this.table.loading = false
      }
    },
    cascadeDeleteStudent,

    /** Subscriptions **/
    onCreateStudent() {
      this.table.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateStudent)).subscribe((sourceData) => {
        this.syncNotification()
        const student = sourceData.value.data.onCreateStudent
        if (student && !this.table.items.map(item => item.id).includes(student.id)) {
          if(this.user.groups.includes('Teacher')) {
            //prevent teacher from getting on create students
          }
          else {
            this.table.loading = true
            this.table.items = [student, ...this.table.items];
            this.table.loading = false
          }
        }
      });
    },
    onUpdateStudent() {
      this.table.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateStudent)).subscribe((sourceData) => {
        this.syncNotification()
        const student = sourceData.value.data.onUpdateStudent
        if (student && this.table.items.map(item => item.id).includes(student.id)) {
          this.table.loading = true
          const index = this.table.items.findIndex(item => item.id === student.id)
          if(index > -1) {
            this.table.items.splice(index, 1, student)
          }
          this.table.loading = false
        }
      });
    },
    onDeleteStudent() {
      this.table.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteStudent)).subscribe((sourceData) => {
        this.syncNotification()
        const student = sourceData.value.data.onDeleteStudent
        if(student && this.table.items.map(item => item.id).includes(student.id)) {
          this.table.loading = true
          this.table.items = this.table.items.filter(item => item.id !== student.id);
          this.table.loading = false
        }
      });
    },

    /** Actions **/
    async refresh() {
      this.table.loaded = 0
      this.table.loading = true
      await this.listStudents()
    },

    /** UI **/
    searchSchools(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.legal'],
        threshold: 0.2,
        shouldSort: true,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
  }
}
</script>

<style lang="scss">

</style>
