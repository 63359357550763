var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(ref){return [_c('b-breadcrumb-item',{attrs:{"text":("All-State - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"text":"Students","active":""}})]}},{key:"dropdown-options",fn:function(ref){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-rotate-right"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]}},{key:"tour",fn:function(ref){
var tourName = ref.tourName;
return [(_vm.$refs['table-layout'])?_c('tour',{attrs:{"name":tourName,"steps":_vm.$refs['table-layout'].tour.steps,"callbacks":_vm.$refs['table-layout'].tour.callbacks}}):_vm._e()]}},{key:"content",fn:function(ref){
var state = ref.state;
return [_c('table-layout',{ref:"table-layout",attrs:{"fields":_vm.table.fields,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: true },"items":_vm.table.items,"loading":_vm.table.loading,"subscriptions":_vm.table.subscriptions,"func-delete":_vm.deleteStudent,"func-refresh":_vm.refresh,"export-exclude-fields":[
                      'id',
                      'schoolID',
                      'school.id' ]},on:{"mounted":function($event){_vm.table = $event},"updated":function($event){_vm.table = $event}},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('overlay-loading',{attrs:{"items":[
          { state: _vm.options.schools.loading, desc: 'Loading Schools', loaded: _vm.options.schools.loaded },
          { state: _vm.table.loading, desc: 'Loading Students', loaded: _vm.table.loaded },
          { state: state.loading, desc: 'Rendering Template'} ]}})]},proxy:true},{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"School","label-for":"school-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"input-id":"school-input","options":_vm.schools,"label":"name","reduce":function (school) { return school.id; },"loading":_vm.options.schools.loading,"filter":_vm.searchSchools,"select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},scopedSlots:_vm._u([{key:"option",fn:function(ref){
        var name = ref.name;
return [_vm._v(" "+_vm._s(name.legal)+" ")]}},{key:"selected-option",fn:function(ref){
        var name = ref.name;
return [_vm._v(" "+_vm._s(name.legal)+" ")]}}],null,true),model:{value:(_vm.table.filters.school.value),callback:function ($$v) {_vm.$set(_vm.table.filters.school, "value", $$v)},expression:"table.filters.school.value"}})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Grade","label-for":"grade-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"input-id":"grade-input","options":['10', '11'],"searchable":false,"select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.grade.value),callback:function ($$v) {_vm.$set(_vm.table.filters.grade, "value", $$v)},expression:"table.filters.grade.value"}})],1)],1)],1)]},proxy:true},{key:"cell(name)",fn:function(ref){
        var data = ref.data;
return [_c('b-media',{attrs:{"vertical-align":"center","no-body":""}},[_c('b-media-aside',[(_vm.hasCustomAvatar(data.item.avatar))?_c('b-avatar',{attrs:{"variant":"primary","size":"2.5em","badge-variant":"white"}},[_c('b-img',{attrs:{"fluid":"","fluid-grow":"","src":data.item.avatar.src,"alt":data.item.avatar.name}})],1):_c('b-avatar',{attrs:{"variant":"primary","size":"2.5em","badge-variant":"white"}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icon}})],1)],1),_c('b-media-body',{staticClass:"align-self-center"},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'all-state-student', params: { id: data.item.id } }}},[_vm._v(" "+_vm._s(data.item.name.first)+" "+_vm._s(data.item.name.last)+" ")])],1)],1)]}},{key:"cell(school.name.legal)",fn:function(ref){
        var data = ref.data;
return [(data.item.school)?_c('div',[_vm._v(" "+_vm._s(data.item.school.name.legal)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No School ")])]}},{key:"cell(createdAt)",fn:function(ref){
        var data = ref.data;
return [_c('last-modified',{attrs:{"date":data.item.createdAt,"no-text":""}})]}},{key:"cell(updatedAt)",fn:function(ref){
        var data = ref.data;
return [_c('last-modified',{attrs:{"date":data.item.updatedAt,"no-text":""}})]}},{key:"cell(state.enabled)",fn:function(ref){
        var data = ref.data;
return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (data.item.state.enabled ? 'success' : 'danger'))}},[_vm._v(" "+_vm._s(data.item.state.enabled)+" ")])]}},{key:"cell(row-options)",fn:function(ref){
        var data = ref.data;
return [_c('b-dropdown-item',{staticClass:"table-row-option-view",attrs:{"to":{ name: 'all-state-student', params: { id: data.item.id } }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View")])],1),_c('can',{attrs:{"do":"delete","on":"all-state-students"}},[_c('b-dropdown-divider'),_c('b-dropdown-item',{staticClass:"table-row-option-delete",on:{"click":function($event){return _vm.$refs.layout.confirmDelete(data.item, _vm.deleteStudent, _vm.cascadeConfirmDeleteOptions)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)]}}],null,true)})]}},{key:"debug",fn:function(){return [_c('debug',{attrs:{"collapsed":true}},[_vm._v(_vm._s(_vm.table))])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }